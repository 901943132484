<template lang="">
    <div>
        <h1>Vue</h1>
        <Counter />
    </div>
</template>
<script>
    import Counter from './Counter.vue'
    export default {
        components: {
            Counter
        }
    }
</script>
<style lang="">
    
</style>